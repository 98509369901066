*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.match-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #046538;
}

.match-container::-webkit-scrollbar {
    width: 6px;

    background-color: #f5f5f5;
}

.match-container::-webkit-scrollbar-thumb {
    background-color: #cad311;
}

.matches {
    display: flex;

    padding: 20px 15px;

    font-family: "Montserrat", sans-serif;

    gap: 10px;
    max-width: 1280px;
    margin: 0 auto;
}

.matchCategory {
    flex: 0 220px;
}

.match-container {
    min-height: 300px;
    position: relative;

    padding-right: 0px;

    flex: 1;
}

.matchCategory ul {
    display: flex;

    flex-direction: column;

    margin: 0;
}

.matchCategory ul li {
    list-style: none;

    padding: 10px 15px;

    cursor: pointer;

    border-radius: 5px;

    display: flex;

    align-items: center;
}

.matchCategory ul li.active,
.matchCategory ul li:hover {
    background-color: #033112;

    color: #fff;
}

.matchCategory ul li>img {
    margin-right: 10px;

    filter: invert(2);

    width: 20px;
}

.matchCategory ul li.active,
.matchCategory ul li:hover {
    background-color: #033112;

    color: #fff;
}

.matchCategory ul li.active>img,
.matchCategory ul li:hover>img {
    filter: brightness(100);
}

.matchList {
    display: flex;

    flex-wrap: wrap;

    justify-content: flex-start;

    align-content: flex-start;

    gap: 8px;

    width: 100%;
    min-height: 300px;
}

.matchBox {
    display: flex;

    gap: 10px;

    border: 1px solid #333;

    padding: 15px;

    border-radius: 10px;

    flex: 0 calc(50% - 10px);

    justify-content: center;

    flex-wrap: wrap;

    background-repeat: no-repeat;

    background-position: center right;

    background-color: #033112;

    color: #fff;

    align-items: center;
}

.ESPORTS {
    background-image: url(../assets/images/esports-bg.png);
}

.BASKETBALL {
    background-image: url(../assets/images/basketball-bg.png?v=1.2);
}

.FOOTBALL {
    background-image: url(../assets/images/football-bg.png);
}

.TENNIS {
    background-image: url(../assets/images/tennis-bg.png);
}

.CRICKET {
    background-image: url(../assets/images/cricket-bg.png);
}

.League {
    flex: 0 100%;

    font-weight: 600;

    color: #ffbb00;

    font-size: 20px;
}

.schedule {
    flex: 0 100%;

    display: flex;

    gap: 10px;

    color: #fff;

    margin-bottom: 10px;
}

.schedule>p {
    display: flex;

    align-items: center;

    gap: 5px;

    padding-bottom: 0 !important;
}

.matchinner {
    flex: 1;

    width: 100%;
}

.matchwrapper {
    flex: 0 100%;

    display: flex;

    align-items: center;

    justify-content: flex-start;
}

.matchwrapper .teams {
    flex: 0 auto;

    text-align: center;

    padding-top: 5px;

    padding-bottom: 5px !important;

    font-weight: 600;
}

.matchwrapper .versus {
    flex: 0 55px;

    text-align: center;

    color: #ffbb00;

    padding-bottom: 0 !important;
}

.matchBox:hover {
    background-color: rgb(2, 84, 28);

    cursor: pointer;
}

.matchLive {
    flex: 1;
}

.matchLive>iframe {
    width: 100%;

    height: 100%;
}

.matchlink {
    display: flex;

    align-items: center;

    height: 100%;

    flex-direction: column;

    flex: 0 70px;

    justify-content: center;
}

.isLive {
    flex: 1;

    text-transform: uppercase;

    font-size: 14px;

    margin-bottom: 10px;
}
.isLive.hide{
    visibility: hidden;
}
.isLive:before {
    content: "";

    width: 8px;

    height: 8px;

    background-color: #e73a3f;

    box-shadow: #e73a3f 0 0 8px 1px;

    display: inline-block;

    border-radius: 50%;

    margin-right: 4px;

    animation: blinking 1s linear infinite;
}

@keyframes blinking {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.6;
    }
}

.btn-link {
    background-color: rgb(119 20 20 / 57%);

    border: 1px solid #e73a3f;

    border-radius: 50%;

    width: 70px;

    height: 70px;

    flex: 0 70px;

    cursor: pointer;

    transition: all 200ms linear;

    padding: 0;
}

.btn-link:hover {
    background-color: #e73a3f;
}

.btn-link:hover img {
    filter: brightness(100);
}

@media only screen and (max-width: 980px) {
    .matchBox {
        flex: 0 100%;
    }

    .matchCategory {
        flex: 1;

        /* flex-direction: column; */

        overflow: scroll;
    }

    .matches {
        display: flex;

        padding: 20px 15px;

        font-family: "Montserrat", sans-serif;

        gap: 10px;

        flex-direction: column;
    }

    .matchCategory ul {
        display: flex;

        margin: 0;

        padding: 0 !important;

        flex-direction: row;
    }

    .matchCategory ul li {
        list-style: none;

        padding: 10px 22px;

        cursor: pointer;

        border-radius: 5px;

        max-width: fit-content;

        display: flex;

        flex-direction: row;

        justify-content: center;

        /* width: 220px; */
    }
}



.match-lightbox {
    width: 100%;

    height: 100%;
    padding: 50px 0px 30px;
    position: relative;
    margin: 0 auto;
}

.matchInfo h3,
.matchInfo p {
    text-align: center;

    color: #fff;
}

.match-lightbox iframe {
    max-width: 620px;

    width: 100%;

    height: 380px;

    border: none;

    display: block;

    margin: 0 auto;
}

.match-lightbox>button {
    position: absolute;

    right: 0;

    top: 0;

    border: 0px solid #fff;

    background-color: #000;

    color: #fff;

    padding: 6px 8px;

    width: 30px;

    height: 30px;

    font-size: 18px;

    cursor: pointer;
}

.match-lightbox>button:hover {
    background-color: #e73a3f;
}

.box-spinner {
    height: 100px;

    width: 100px;

    border-radius: 50%;

    border: 3px solid;

    border-color: orange transparent;

    animation: spin 1s infinite ease-out;

    position: absolute;

    top: 45%;

    left: 45%;

    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 980px) {
    .box-spinner {
        top: 35%;

        left: 35%;
    }

    .match-container {
        min-height: 300px;
    }
}

.btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-link a {
    flex: 1 100%;
    text-align: center;
    height: 100%;
    line-height: 90px;
    display: flex;
    justify-content: center;
}

.btn-link img {
    width: 35px !important;
    max-width: unset;
}

#match-lighbox-overlay .box-spinner {
    top: 20%;
}
.container{
    padding: 30px 20px;
}
.btn-back{
    background-color: #033112;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 20px;
    font-family: sans-serif;
}

.pagination {
    display: block;
    text-align: center;
    padding: 30px 0;
}

.pagination span {
    display: inline-block;
    padding: 0 20px;
}

.pagination a {
    border: 0;
    background-color: #033112;
    color: #fff;
    padding: 10px 15px;
    border-radius: 30px;
    min-width: 85px;
    cursor: pointer;
    text-decoration: none;
}

.pagination a:hover {
    background-color: #046538;
}

.pagination a.disabled {
    background-color: #d1d1d1;
    cursor: not-allowed;
    pointer-events: none;
}

.stats-pagination{
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
}
.match-stats{
    padding: 40px 20px;
}


@media screen and (max-width: 480px) {
    .teams {
        font-size: 10px;
    }

    .League {
        font-size: 12px;
    }

    .schedule {
        font-size: 10px;
    }

    .isLive {
        font-size: 9px;
    }

    .isLive::before {
        width: 6px;

        height: 6px;
    }

    .versus {
        font-size: 10px;
    }

    .matchlink {
        flex: 0 50px;
    }

    .matchBox {
        background-position: 63% center;
        background-size: cover;
    }

    .btn-link {
        width: 50px;

        height: 50px;

        flex: 0 50px;
    }

    .btn-link a {
        flex: 1 100%;
        text-align: center;
        height: 100%;
        line-height: 72px;
    }
}

.livestream{
    display: block;
    margin: 0 auto;
}

.video-container{
    display: block;
    position: relative;
    max-width: 1024px;
    height: 505px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #000;
    padding-bottom: 20px;
}
.video-container iframe{
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.fullscreenIcon{
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: all 1000ms linear;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
    font-family: sans-serif;
   width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: all 0.8s ease-in;
}

.fullscreenIcon img{
  max-width: 30px;
}
.fullscreenIcon small{
    font-size: 14px;
}
.liveVisit{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9999;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
}
.liveVisit > small{
    background-color: #e73a3f;
    color: #fff;
    font-size: 14px;
    padding: 2px;
    border-radius: 2px;
}
.liveVisit span {
    background-color: #000000b5;
    color: #fff;
    font-size: 14px;
    padding: 2px;
    border-radius: 2px;
    line-height: 0;
    width: max-content;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.liveVisit span > img{
    fill: white;
    width: 100%;
    max-width: 20px;
}

/* Fullscreen styles for mobile */
.iframe-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    border: none;
}

/* Hide overflow when in fullscreen mode */
body.iframe-fullscreen-mode {
    overflow: hidden;
}


@media screen and (max-width:  600px) {
    #root{
        height: 100vh;
    }
    .video-wrapper {
        height: 100%;
        background: #000;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
   }
   .video-container{
    flex: 0 100%;
    margin-top: 0%;
    
   }
}

@media  screen and (max-width: 425px) {
    .video-container{
        height: 325px;
    }
    .video-container.iframe-fullscreen{
        height: 100%;
    }
}


.iconfullscreen{
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(../assets/images/icon-fullscreen.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.iconfullscreen.in-fullscreen{
    background-image: url(../assets/images/exit-fullscreen.png);

}
